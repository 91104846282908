import {Component} from 'react';
import React from 'react';
import Modal from "react-responsive-modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import ValidatedFormComponent from "../ValidatedFormComponent";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import crypto from "crypto";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";

const keySizes = {
    "aes-128-ctr": 16,
    "aes-192-ctr": 24,
    "aes-256-ctr": 32
}

class FormDialog extends Component {
    constructor(props) {
        /*
            props:
                - data
                - open

                - onClose
                - onSubmit

                - loading

        */
        super(props);

        this.state = {
            encType: "aes-256-ctr",
            encPass: "",
            encPassType: "ascii"
        }
    }

    submit() {

        if(this.passwordRef.validated()) {
            let decrypted;
            let key;

            if (this.state.encPassType === "ascii") {
                key = Buffer.from(this.state.encPass, "ascii");
            } else if (this.state.encPassType === "hex") {
                key = Buffer.from(this.state.encPass, "hex");
            } else {
                key = Buffer.from(this.state.encPass, "base64");
            }

            //Decrypt the message
            const hash = crypto.createHash('sha256').update(key).digest();
            //if (keySizes[this.state.encType] === key.length) {
                this.props.addToKnownKeys(this.props.from, this.state.encType, hash.slice(0,keySizes[this.state.encType]).toString("hex"));
                this.props.onClose();
            //}
        }

    }
    
    render() {


        let decrypted;
        let key;

        if(this.props.data!=null) {
            if (this.state.encPassType === "ascii") {
                key = this.state.encPass;
            } else if (this.state.encPassType === "hex") {
                try {
                    key = Buffer.from(this.state.encPass, "hex");
                } catch (e) {

                }
            } else {
                try {
                    key = Buffer.from(this.state.encPass, "base64");
                } catch (e) {

                }
            }
            //Decrypt the message
            try {
                const hash = crypto.createHash('sha256').update(key).digest();
                console.log(hash.toString("hex"));
                //if (key != null && keySizes[this.state.encType] === key.length) {
                    const decipher = crypto.createDecipheriv(this.state.encType, hash.slice(0, keySizes[this.state.encType]), hash.slice(0,16));
                    let dec = decipher.update(this.props.data, 'hex', 'utf8');
                    dec += decipher.final('utf8');
                    decrypted = dec;
                //}
            } catch(e) {
                console.log(e);
            }
        }

        return (
            <Modal open={this.props.open} onClose={this.props.onClose} styles={{
                modal: {
                    minWidth: '400px',
                    width: '600px'
                }
            }} >
                <h2>Decrypt data</h2>

                <Form horizontal>

                    <ValidatedFormComponent key={"Password"}
                                            inputRef={(ref) => {
                                                this.passwordRef = ref;
                                            }}
                                            onChange={(txt) => {
                                                this.setState({
                                                    encPass: txt
                                                });
                                            }}
                                            onValidate={(txt) => {
                                                return txt.length===0 ? "Key cannot be empty" : null;
                                            }}
                                            value={this.state.encPass}
                                            name={"Password"}
                                            type={"password"}/>

                    <ValidatedFormComponent key={"Password type"}
                                            inputRef={(ref) => {
                                                this.encPassTypeRef = ref;
                                            }}
                                            onChange={async (txt) => {
                                                await this.setState({
                                                    encPassType: txt,
                                                });
                                                this.passwordRef.validated();
                                            }}
                                            onValidate={() => null}
                                            value={this.state.encPassType}
                                            name={"Password type"}
                                            type={"select"}
                                            value_key={"value"}
                                            display_name_key={"display_name"}
                                            options={[
                                                {
                                                    value: "ascii",
                                                    display_name: "ASCII"
                                                },
                                                {
                                                    value: "base64",
                                                    display_name: "Base64"
                                                },
                                                {
                                                    value: "hex",
                                                    display_name: "Hex"
                                                }
                                            ]}/>

                    <ValidatedFormComponent key={"Type"}
                                            inputRef={(ref) => {
                                                this.typeRef = ref;
                                            }}
                                            onChange={async (txt) => {
                                                await this.setState({
                                                    encType: txt,
                                                });
                                                this.passwordRef.validated();
                                            }}
                                            onValidate={() => null}
                                            value={this.state.encType}
                                            name={"Cipher type"}
                                            type={"select"}
                                            value_key={"value"}
                                            display_name_key={"display_name"}
                                            options={[
                                                {
                                                    value: "aes-128-ctr",
                                                    display_name: "AES-128-CTR"
                                                },
                                                {
                                                    value: "aes-192-ctr",
                                                    display_name: "AES-192-CTR"
                                                },
                                                {
                                                    value: "aes-256-ctr",
                                                    display_name: "AES-256-CTR"
                                                }
                                            ]}/>

                    <Row>
                        <Col sm={12}>
                            <Form.Label >
                                <b>Decrypted data</b>
                            </Form.Label>
                        </Col>
                        <Col sm={12}>
                            {decrypted==null ? (
                                <Badge>Cannot decrypt</Badge>
                            ) : decrypted}
                        </Col>
                    </Row>


                    <Alert variant="success" className={"m-1 mt-3"}>
                        <Alert.Heading>Save for all records from this address</Alert.Heading>
                        <hr />
                        <p>
                            You can save the password for decrypting all the records coming from this address. All subsequent records will be auto-decrypted using this password. Password is stored in your browser and never leaves your computer!
                        </p>
                    </Alert>

                        <Row>
                            <Col xs={6}>
                                <Button
                                    className={"mt-3"}
                                    style={{width: "100%"}}
                                    bsStyle="primary"
                                    disabled={this.props.loading}
                                    onClick={this.submit.bind(this)}>

                                    Save
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    className={"mt-3"}
                                    style={{width: "100%"}}
                                    variant="danger"
                                    onClick={this.props.onClose}>

                                    Back
                                </Button>
                            </Col>
                        </Row>
                </Form>

            </Modal>
        );
    }
}

export default FormDialog;