import {Component} from 'react';
import React from 'react';
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

class ValidatedFormComponent extends Component {

    constructor(props) {
        /*
            Props:
             - onValidate (function(input))
             - inputRef (function(ref)) - ref contains .validated() function to determine if input is validated OK
             - onChange (function(value))

             - bsSize
             - validationState
             - validationMessage

             - value
             - disableFeedback

             - name
             - type (text,number,boolean,password,select,textarea,file,checkbox)
             - placeholder

             #IF type=='select'
                - value_key
                - display_name_key
                - disabled_key
                - options
                  - value
                  - display_name
             #ENDIF

             #IF type=='checkbox'
                - indeterminate
             #ENDIF

             #IF type=='number'
                - emptyAllowed
                - min
                - max
                - step
                - decimal
             #ENDIF

         */
        super(props);

        if(this.props.onValidate==null) {
            this.props.onValidate = function() {
                return null;
            }
        }

        if(this.props.type==='boolean') {
            this.state = {
                value: props.value==null ? 'false' : props.value,
                errMsg: null
            };
            if(this.props.inputRef!=null) {
                const ref = {
                    validated: this.validated.bind(this),
                    value: this.state.value
                }
                this.props.inputRef(ref);
            }
            return;
        }

        if(this.props.type==='checkbox') {
            this.state = {
                value: props.value==null ? 'false' : props.value,
                errMsg: null
            };
            if(this.props.inputRef!=null) {
                const ref = {
                    validated: this.validated.bind(this),
                    value: this.state.value
                }
                this.props.inputRef(ref);
            }
            return;
        }

        if(this.props.type==='radiobox') {
            this.state = {
                value: props.value==null ? props.options[0].value : props.value,
                errMsg: null
            };
            if(this.props.inputRef!=null) {
                const ref = {
                    validated: this.validated.bind(this),
                    value: this.state.value
                }
                this.props.inputRef(ref);
            }
            return;
        }

        if(this.props.type==='bigselect') {
            this.state = {
                value: props.value==null ? null : props.value,
                errMsg: null
            };
            if(this.props.inputRef!=null) {
                const ref = {
                    validated: this.validated.bind(this),
                    value: this.state.value
                }
                this.props.inputRef(ref);
            }
            return;
        }

        this.state = {
            value: props.value==null ? '' : props.value,
            errMsg: null
        };
    }

    validated() {
        if(this.props.type==='number') {
            const numValid = this.numberValidator(this.state.value);
            if(numValid!=null) {
                this.setState({
                    errMsg: numValid
                });
                //document.getElementById("scroller"+this.props.type+this.props.name.replace(new RegExp(" ",'g'), "")).click();
                return false;
            }
        }
        const errMsg = this.props.onValidate(this.state.value);
        this.setState({
            errMsg: errMsg
        });
        if(errMsg!=null) {
            //document.getElementById("scroller"+this.props.type+this.props.name.replace(new RegExp(" ",'g'), "")).click();
        }
        return errMsg==null;
    }

    numberValidator(value) {
        if(this.props.emptyAllowed && value==='') return null;
        if(value==='') return 'Value cannot be empty!';
        const val = this.props.decimal ? parseFloat(value) : parseInt(value);
        if(isNaN(val)) return 'Value must be a number!';
        if(this.props.min!=null && val<this.props.min) return 'Value cannot be larger than '+this.props.min+'!';
        if(this.props.max!=null && val>this.props.max) return 'Value cannot be smaller than '+this.props.max+'!';
        return null;
    }

    componentDidMount() {
        if(this.inputRef!=null) {
            this.inputRef.indeterminate = this.props.indeterminate;
        }
    }

    componentDidUpdate() {
        if(this.inputRef!=null) {
            this.inputRef.indeterminate = this.props.indeterminate;
        }
    }

    render() {
        const errMsg = this.state.errMsg;
        const options = [];
        if(this.props.type==='select') {
            let i = 0;
            if (this.props.options != null && this.props.value_key != null && this.props.display_name_key != null) for (let opt of this.props.options) {
                options.push(
                    (
                        <option key={'key' + i} value={opt[this.props.value_key]}
                                disabled={this.props.disabled_key == null ? false : opt[this.props.disabled_key]}>{opt[this.props.display_name_key]}</option>
                    )
                )
                i++;
            }
        }

        return (
            <section id={this.props.type+(this.props.name!=null ? this.props.name.replace(new RegExp(" ",'g'), "") : "")}>
                <Form.Group controlId={this.props.name}
                            bsStyle={this.props.bsStyle} validationState={this.props.validationState==null ? errMsg==null ? null : 'error' : this.props.validationState}>
                    <a className="page-scroll" id={"scroller"+this.props.type+(this.props.name!=null ? this.props.name.replace(new RegExp(" ",'g'), "") : "")} href={"#"+this.props.type+(this.props.name!=null ? this.props.name.replace(new RegExp(" ",'g'), "") : "")}/>
                    <Row>
                    {this.props.name!=null ? (
                        <Col xs={12} componentClass={Form.Label}>
                            <Form.Label >
                                <b>{this.props.name}</b>
                            </Form.Label>
                        </Col>
                        ) : ''}

                    <Col style={
                        this.props.name==null ? {
                            paddingRight: '0px',
                            paddingLeft: '0px',
                        } : null
                    }>
                        {
                            this.props.type==='text' ?
                                (
                                    <Form.Control
                                        type="text"
                                        bsSize={this.props.bsSize}
                                        bsStyle={this.props.bsStyle}
                                        value={this.props.value!=null ? this.props.value : this.state.value}
                                        isInvalid={(errMsg!=null || this.props.validationMessage!=null)}
                                        onChange={(evnt) => {
                                        const errMsg = this.props.onValidate(evnt.target.value);
                                        this.setState({
                                            value: evnt.target.value,
                                            errMsg: errMsg
                                        });
                                        if(this.props.onChange!=null) this.props.onChange(evnt.target.value);
                                    }} placeholder={this.props.placeholder} ref={ref => {
                                        if(ref==null || this.props.inputRef==null) return;
                                        ref.validated = this.validated.bind(this);
                                        this.props.inputRef(ref);
                                    }} />
                                ) :
                            this.props.type==='file' ? (
                                    <Form.Control
                                        type="file"
                                        bsSize={this.props.bsSize}
                                        bsStyle={this.props.bsStyle}
                                        value={this.props.value!=null ? this.props.value : this.state.value}
                                        isInvalid={(errMsg!=null || this.props.validationMessage!=null)}
                                        onChange={(evnt) => {
                                        const errMsg = this.props.onValidate(evnt.target.value);
                                        this.setState({
                                            value: evnt.target.value,
                                            errMsg: errMsg
                                        });
                                        if(this.props.onChange!=null) this.props.onChange(evnt.target.value);
                                    }} placeholder={this.props.placeholder} ref={ref => {
                                        if(ref==null || this.props.inputRef==null) return;
                                        ref.validated = this.validated.bind(this);
                                        this.props.inputRef(ref);
                                    }} />
                                ) :
                            this.props.type==='textarea' ? (
                                    <Form.Control
                                        as="textarea"
                                        bsSize={this.props.bsSize}
                                        bsStyle={this.props.bsStyle}
                                        value={this.props.value!=null ? this.props.value : this.state.value}
                                        isInvalid={(errMsg!=null || this.props.validationMessage!=null)}
                                        onChange={(evnt) => {
                                        const errMsg = this.props.onValidate(evnt.target.value);
                                        this.setState({
                                            value: evnt.target.value,
                                            errMsg: errMsg
                                        });
                                        if(this.props.onChange!=null) this.props.onChange(evnt.target.value);
                                    }} placeholder={this.props.placeholder} ref={ref => {
                                        if(ref==null || this.props.inputRef==null) return;
                                        ref.validated = this.validated.bind(this);
                                        this.props.inputRef(ref);
                                    }} />
                                ) :
                            this.props.type==='select' ?
                                (
                                    <Form.Control
                                        as="select"
                                        bsSize={this.props.bsSize}
                                        bsStyle={this.props.bsStyle}
                                        value={this.props.value!=null ? this.props.value : this.state.value}
                                        isInvalid={(errMsg!=null || this.props.validationMessage!=null)}
                                        onChange={(evnt) => {
                                        const errMsg = this.props.onValidate(evnt.target.value);
                                        this.setState({
                                            value: evnt.target.value,
                                            errMsg: errMsg
                                        });
                                        if(this.props.onChange!=null) this.props.onChange(evnt.target.value);
                                    }} placeholder={this.props.placeholder} ref={ref => {
                                        if(ref==null || this.props.inputRef==null) return;
                                        ref.validated = this.validated.bind(this);
                                        this.props.inputRef(ref);
                                    }} >
                                        {options}
                                    </Form.Control>
                                ) :
                            this.props.type==='password' ?
                                (
                                    <Form.Control
                                        type="password"
                                        bsSize={this.props.bsSize}
                                        bsStyle={this.props.bsStyle}
                                        value={this.props.value!=null ? this.props.value : this.state.value}
                                        isInvalid={(errMsg!=null || this.props.validationMessage!=null)}
                                        onChange={(evnt) => {
                                        const errMsg = this.props.onValidate(evnt.target.value);
                                        this.setState({
                                            value: evnt.target.value,
                                            errMsg: errMsg
                                        });
                                        if(this.props.onChange!=null) this.props.onChange(evnt.target.value);
                                    }} placeholder={this.props.placeholder} ref={ref => {
                                        if(ref==null || this.props.inputRef==null) return;
                                        ref.validated = this.validated.bind(this);
                                        this.props.inputRef(ref);
                                    }} />
                                ) :
                            this.props.type==='number' ?
                                (
                                    <Form.Control type="number"
                                                  isInvalid={(errMsg!=null || this.props.validationMessage!=null)}
                                                 bsSize={this.props.bsSize}
                                                 bsStyle={this.props.bsStyle}
                                                 value={this.props.value!=null ? (isNaN(this.props.value) ? "" : this.props.value) : this.state.value}
                                                 min={isNaN(this.props.min) ? "" : this.props.min}
                                                 max={isNaN(this.props.max) ? "" : this.props.max}
                                                 step={isNaN(this.props.step) ? "" : this.props.step}
                                                 onChange={(evnt) => {
                                                     const numValid = this.numberValidator(evnt.target.value);
                                                     if(numValid!=null) {
                                                         this.setState({
                                                             value: evnt.target.value,
                                                             errMsg: numValid
                                                         });
                                                         if(this.props.onChange!=null) this.props.onChange(evnt.target.value);
                                                         return;
                                                     }
                                                     const errMsg = this.props.onValidate(evnt.target.value);
                                                     this.setState({
                                                         value: evnt.target.value,
                                                         errMsg: errMsg
                                                     });
                                                     if(this.props.onChange!=null) this.props.onChange(evnt.target.value);
                                    }} placeholder={this.props.placeholder} ref={ref => {
                                        if(ref==null || this.props.inputRef==null) return;
                                        ref.validated = this.validated.bind(this);
                                        this.props.inputRef(ref);
                                    }} />
                                ) : ''
                        }

                        {this.props.disableFeedback ? '' : (<Form.Control.Feedback type="invalid">
                            {this.props.validationMessage==null ? errMsg : this.props.validationMessage}
                        </Form.Control.Feedback>)}
                    </Col>
                    </Row>
                </Form.Group>
            </section>
        );
    }
}

export default ValidatedFormComponent;