import React from 'react';
import logo from './logo.svg';
import './App.css';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import request from "request-promise-native";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Badge from "react-bootstrap/Badge";
import FormDialog from "./utils/modals/FormDialog";
import Alert from "react-bootstrap/Alert";
import ValidatedFormComponent from "./utils/ValidatedFormComponent";
import Form from "react-bootstrap/Form";
import Modal from "react-responsive-modal";
import DecryptDialog from "./utils/modals/DecryptDialog";
import crypto from "crypto";


const URL_TO_CONNECT = "";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addressStr: "",
            started: false,
            loading: false,
            txs: null,
            txsAddr: null,
            txsErr: null,
            decryptData: null,
            decryptAddr: null,
            decryptFrom: null,

            knownKeys: {},
        };
    }

    componentDidMount() {
        const obj = {};
        for(let i=0, len=localStorage.length; i<len; i++) {
            const key = localStorage.key(i);
            if(key.startsWith("key_")) {
                try {
                    const value = localStorage.getItem(key);
                    const valObj = JSON.parse(value);
                    obj[key.split("_")[1]] = valObj;
                } catch(e) {

                }
            }
        }
        this.setState({
            knownKeys: obj
        });

        this.queryDict = {}
        window.location.search.substr(1).split("&").forEach(function(item) {
            this.queryDict[item.split("=")[0]] = item.split("=")[1]
        }.bind(this));
        console.log("Query dict:",this.queryDict);

        if(this.queryDict['w']!=null) {
            this.fetchTxsForAddress(this.queryDict["w"]);
        }
    }

    addToKnownKeys(addr, encryption, password) {
        const obj = {... this.state.knownKeys};
        obj[addr] = {
            encryption,
            password,
            addr
        }
        localStorage.setItem("key_"+addr, JSON.stringify(obj[addr]));
        console.log("Adding to known keys for: "+addr+" enc "+encryption+" pwd "+password);
        this.setState({
            knownKeys: obj
        });
    }

    componentWillUnmount() {

    }

    async fetchTxsForAddress(addr) {
        this.setState({
            loading: true,
            txs: null,
            started: true,
            txErr: null,
            txsAddr: null
        });

        const options = {
            method: 'GET',
            uri: "https://be.cryptostry.com/insight-lite-api/txs/?address="+addr,
            json: true,
            resolveWithFullResponse: true,
            simple: false
        };

        try {
            const response = await request(options);

            console.log("Response code: ",response.statusCode);

            if(response.statusCode===200) {
                const txs = [];

                for(let e of response.body.txs) {
                    let contains = false;
                    for(let output of e.vout) {
                        if(output.scriptPubKey!=null && output.scriptPubKey.addresses!=null && output.scriptPubKey.addresses.includes(addr)) {
                            contains = true;
                            break;
                        }
                    }
                    if(contains) {
                        let data = null;
                        for(let output of e.vout) {
                            if(output.scriptPubKey!=null && output.scriptPubKey.hex!=null && output.scriptPubKey.hex.startsWith("6a")) {
                                data = output.scriptPubKey.hex;
                            }
                        }

                        if(data!=null) {
                            txs.push({
                                txid: e.txid,
                                time: e.time,
                                size: e.size,
                                confirmations: e.confirmations,
                                version: e.version,

                                from: e.vin[0].addr,
                                data
                            });
                        }
                    }
                }

                console.log("Filtered txs: ", txs);

                this.setState({
                    txs,
                    txsAddr: addr,
                    loading: false
                });
                return;
            }
        } catch (e) {
            console.log(e);
        }

        this.setState({
            txErr: "Failed",
            loading: false
        });
    }

    render() {
        let title = this.state.txsAddr;
        if(this.state.txs!=null && this.state.txs.length>0) {
            const key = this.state.knownKeys[this.state.txs[this.state.txs.length-1].from];
            console.log("Key,", key);
            if(key!=null) {
                let keyB = Buffer.from(key.password, "hex");
                const decipher = crypto.createDecipheriv(key.encryption, keyB, keyB.slice(0, 16));
                let dec = decipher.update(this.state.txs[this.state.txs.length-1].data.substring(4), 'hex', 'utf8');
                dec += decipher.final('utf8');

                title = (
                    <div>
                        <b>{dec}</b> {title}
                    </div>
                );
            } else {
                title = (
                    <div>
                        <b>{Buffer.from(this.state.txs[this.state.txs.length-1].data.substring(4), "hex").toString()}</b> {title}
                    </div>
                )
            }

        }

        return (
            <div className="App">

                <DecryptDialog
                    open={this.state.decryptData!=null}
                    onClose={() => {
                        this.setState({
                            decryptData: null
                        })
                    }}
                    data={this.state.decryptData}
                    address={this.state.decryptAddr}
                    from={this.state.decryptFrom}
                    addToKnownKeys={this.addToKnownKeys.bind(this)}
                />


                <header className="App-header">
                    <Navbar bg="dark" variant="dark">
                        <Navbar.Brand href="#home"><h2>
                            <b>Crypto</b>stry
                        </h2></Navbar.Brand>
                        <Nav className="mr-auto">
                        </Nav>
                        <Col sm={3}>
                            <Form.Control
                                type="text"
                                placeholder={"Input the address"}
                                onChange={(txt) => {
                                    this.setState({
                                        addressStr: txt.target.value
                                    });
                                }}
                                value={this.state.addressStr}
                            />
                        </Col>
                        <Button onClick={() => {
                            this.fetchTxsForAddress(this.state.addressStr);
                        }}>
                            Search
                        </Button>
                    </Navbar>

                    {this.state.txErr!=null ? (
                        <Alert variant="danger" className={"m-3"} onClose={() => this.setState({
                            txErr: null
                        })} dismissible>
                            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                            <hr />
                            <p>
                                Invalid address supplied! Please make sure you input the correct address!
                            </p>
                        </Alert>
                    ) : ""}

                    {this.state.started===false ? (
                        <Alert variant="success" className={"m-3"}>
                            <Alert.Heading>Welcome to cryptostry website!</Alert.Heading>
                            <hr />
                            <p>
                                Start by typing in the address you want to lookup!
                            </p>
                        </Alert>
                    ) : ""}

                    <div className="mx-3 my-5">

                        {this.state.txsAddr!=null ? (
                            <h3>{title}</h3>
                        ) : ""}

                        <ReactTable
                            showPagination={true}
                            defaultPageSize={10}
                            sortable={false}
                            loading={this.state.loading}
                            defaultSorted={[
                                {
                                    id: "time",
                                    desc: true
                                }
                            ]}
                            data={this.state.txs==null ? [] : this.state.txs}
                            columns={[
                                {
                                    Header: "Time",
                                    accessor: "time",
                                    Cell: (row) => {
                                        return new Date(row.original.time*1000).toLocaleTimeString();
                                    },
                                    sortable: true,
                                    width: 200,
                                },
                                {
                                    Header: "Confirmations",
                                    accessor: "confirmations",
                                    width: 100,
                                },
                                {
                                    Header: "TX ID",
                                    accessor: "txid",
                                    width: 100,
                                    Cell: (row) => (
                                        <a href={"https://live.blockcypher.com/ltc/tx/"+row.original.txid} target={"_blank"}>
                                            {row.original.txid}
                                        </a>
                                    )
                                },
                                {
                                    Header: "From",
                                    accessor: "from",
                                    width: 350
                                },
                                {
                                    Header: "Data",
                                    accessor: "data",
                                    Cell: (row) => {
                                        let str = Buffer.from(row.original.data.substring(4), "hex").toString();
                                        const key = this.state.knownKeys[row.original.from];
                                        if(key!=null) {
                                            let keyB = Buffer.from(key.password,"hex");
                                            const decipher = crypto.createDecipheriv(key.encryption, keyB, keyB.slice(0,16));
                                            let dec = decipher.update(row.original.data.substring(4), 'hex', 'utf8');
                                            dec += decipher.final('utf8');
                                            if(/[\x00-\x1F\x80-\xFF]/.test(dec)) {
                                                return (
                                                    <div>
                                                        {str}
                                                        <a className={"ml-3"} href={"javascript:void(0);"} onClick={() => {
                                                            this.setState({
                                                                decryptData: row.original.data.substring(4),
                                                                decryptAddr: this.state.txsAddr,
                                                                decryptFrom: row.original.from
                                                            })
                                                        }}>
                                                            Decrypt
                                                        </a>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div>
                                                        <Badge variant="dark">Auto-Decrypted</Badge> {dec}
                                                    </div>
                                                );
                                            }
                                        }
                                        return (
                                            <div>
                                                {str}
                                                <a className={"ml-3"} href={"javascript:void(0);"} onClick={() => {
                                                    this.setState({
                                                        decryptData: row.original.data.substring(4),
                                                        decryptAddr: this.state.txsAddr,
                                                        decryptFrom: row.original.from
                                                    })
                                                }}>
                                                    Decrypt
                                                </a>
                                            </div>
                                        )
                                    }
                                }
                            ]}
                        />
                    </div>
                </header>
            </div>
        );
    }
}

export default App;
